import { Modal as BootstrapModal } from 'react-bootstrap'

export interface IModal {
  isOpen: boolean
  title?: string | JSX.Element
  children: JSX.Element | JSX.Element[] | string
  onHide: () => void
  buttons?: JSX.Element | JSX.Element[]
  headerClass?: string
  bodyClass?: string
  className?: string
  dialogClassName?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  backdrop?: boolean | 'static' | undefined
  keyboard?: boolean
  scrollable?: boolean
}

/**
 * Renders a modal
 */
function Modal({
  isOpen,
  title,
  children,
  onHide,
  buttons,
  headerClass,
  bodyClass,
  className,
  dialogClassName,
  size,
  backdrop,
  keyboard,
  scrollable,
}: IModal): JSX.Element {
  const computedSize = !size || size === 'md' ? undefined : size
  return (
    <BootstrapModal
      keyboard={keyboard}
      backdrop={backdrop}
      show={isOpen}
      onHide={onHide}
      size={computedSize}
      className={className}
      scrollable={scrollable}
      dialogClassName={dialogClassName}
    >
      {title && (
        <BootstrapModal.Header className={headerClass} onHide={onHide} closeButton>
          <h4 className="modal-title">{title}</h4>
        </BootstrapModal.Header>
      )}
      <BootstrapModal.Body className={bodyClass}>
        <div>{children}</div>
      </BootstrapModal.Body>
      {buttons && <BootstrapModal.Footer className="bg-light py-2 border-top">{buttons}</BootstrapModal.Footer>}
    </BootstrapModal>
  )
}

export default Modal
